
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("L'adresse e-mail"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

        store
          .dispatch(Actions.INIT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              title: 'Réinitialisation du mot de passe',
              text: "Un e-mail contenant les instructions de réinitialisation du mot de passe vous a été envoyé.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, d'accord!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              // router.push({ name: "dashboard" });
              submitButton.value?.removeAttribute("data-kt-indicator");

            });
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");

            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Essayez encore!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
